var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        {
          staticClass: "align-center h1 mx-1",
          attrs: { justify: "start", "no-gutters": "" }
        },
        [
          _c(
            "v-icon",
            {
              class:
                (this.$vuetify.theme.dark
                  ? "white--text"
                  : "grey--text text--darken-2") + " mr-3",
              attrs: { size: "40" }
            },
            [_vm._v(" " + _vm._s(_vm.title.icon) + " ")]
          ),
          _c(
            "span",
            {
              class:
                (this.$vuetify.theme.dark
                  ? "white--text"
                  : "grey--text text--darken-2") + " font-weight-bold"
            },
            [_vm._v(" " + _vm._s(_vm.title.text) + " ")]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "col-12 col-md-6" },
            [
              _c("v-breadcrumbs", {
                staticClass: "mx-1 py-1 px-0",
                attrs: { items: _vm.breadcrumbs }
              })
            ],
            1
          ),
          _c("v-spacer"),
          _vm.showButton
            ? _c(
                "v-btn",
                {
                  staticClass: "mx-3 mb-4 primary",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.handleClick($event)
                    }
                  }
                },
                [
                  _vm.actionButton.icon
                    ? _c(
                        "v-icon",
                        { staticClass: "mr-3", attrs: { small: "" } },
                        [_vm._v(_vm._s(_vm.actionButton.icon))]
                      )
                    : _vm._e(),
                  _vm._v(_vm._s(_vm.actionButton.text) + " ")
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }