var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("div", { attrs: { id: "loader-wrapper" } }, [_vm._m(0)])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "loader" } }, [
      _c("div", { staticClass: "loader-ellips" }, [
        _c("span", { staticClass: "loader-ellips__dot" }),
        _c("span", { staticClass: "loader-ellips__dot" }),
        _c("span", { staticClass: "loader-ellips__dot" }),
        _c("span", { staticClass: "loader-ellips__dot" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }