import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
/**
 * A class that can return the Worklog Statistics model
 */
export class WorklogStatistics {
  /** @param {Object} statistics FastJson Object*/
  constructor(statistics = {}) {
    momentDurationFormatSetup(moment);
    if (statistics.attributes) {
      this.workingDays = statistics.attributes.working_days || null;
      this.workingHours = this.workingDays ?
        moment.duration(statistics.attributes.working_hours_per_day * this.workingDays, 'hours').format('hh.mm') : null;
      this.totalWorklogs = 0;
      const summary = statistics.attributes.projects_summary || [];
      this.summary = [];
      for (const key in summary) {
        if (Object.prototype.hasOwnProperty.call(summary, key)) {
          this.summary.push({
            project: key,
            duration: summary[key] ? moment.duration(summary[key], 'seconds').format('hh:mm:ss', {
              trim: false
            }) : '00:00:00',
            durationInSec: summary[key] ? summary[key] : 0
          });
          this.totalWorklogs += summary[key];
        }
      }
      this.totalWorklogsInSec = this.totalWorklogs;
      this.totalWorklogs = moment.duration(this.totalWorklogs, 'seconds').format('hh:mm:ss', {trim: false});
    }
  }
}
