import {List, ListParams} from 'models/list';
import {Leave} from 'models/leave';
import {UserLeave} from 'models/userLeave';
import {LeaveRequest} from 'models/leaveRequest';
import {leaveStatus} from 'constants';

const state = {
  leaves: new List(),
  userLeaves: new List(),
  userLeavesRequests: new List(),
  userLeavesRequest: new LeaveRequest(),
  managerPendingLeaves: new List(),
  managerHistoryLeaves: new List()
};

const getters = {
  leaves: (state) => state.leaves,
  userLeaves: (state) => state.userLeaves,
  userLeavesRequests: (state) => state.userLeavesRequests,
  userLeavesRequest: (state) => state.userLeavesRequest,
  managerPendingLeaves: (state) => state.managerPendingLeaves,
  managerHistoryLeaves: (state) => state.managerHistoryLeaves
};

const actions = {
  async getLeaves({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.get('/leaves', {params: new ListParams(data)});
    if (response.status === 200) {
      commit('setLeaves', response.data);
      commit('setLoading', false);
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    }
  },
  async createLeave({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.post('/leaves', data);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.leave.entity', 1)
      }));
    } else {
      commit('setLoading', false);
    }
    return response;
  },
  async updateLeave({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.put(`/leaves/${data.leaveId}`, data.form);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.leave.entity', 1)
      }));
    } else {
      commit('setLoading', false);
    }
    return response;
  },
  async destroyLeave({commit}, id) {
    commit('setLoading', true);
    const response = await this.$app.$http.delete(`/leaves/${id}`);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.deleted',
        {entity: this.$app.$tc('models.leave.entity', 1)}));
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.deleted',
        {entity: this.$app.$tc('models.leave.entity', 1)}));
    }
    return response.status;
  },
  async getUserLeaves({commit, rootState}, data) {
    commit('setLoading', true);
    const uuid = data.uuid === 'me' ? rootState.session.currentUser.id : data.uuid;
    const response = await this.$app.$http.get(`/users/${uuid}/leaves`, {
      params: new ListParams({
        sortBy: ['leave_id']
      })
    });
    if (response.status === 200) {
      commit('setUserLeaves', response.data);
      commit('setLoading', false);
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    }
  },
  async updateUserLeave({commit, rootState}, data) {
    commit('setLoading', true);
    const uuid = data.uuid === 'me' ? rootState.session.currentUser.id : data.uuid;
    const response = await this.$app.$http.put(`/users/${uuid}/leaves/${data.id}`, data.leave);
    if (response.status === 200) {
      commit('setUserLeave', {data: response.data, index: data.index});
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.leave.entity', 1)
      }));
    } else {
      commit('setLoading', false);
    }
    return response;
  },
  async requestNewLeave({commit}, leave) {
    commit('setLoading', true);
    const response = await this.$app.$http.post('/leaves_requests', leave);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.created', {
        entity: this.$app.$tc('models.leaveRequest.entity', 1)
      }));
    } else {
      commit('setLoading', false);
    }
    return response;
  },
  async getLeaveRequest({commit}, id) {
    commit('setLoading', true);
    const response = await this.$app.$http.get(`/leaves_requests/${id}`);
    if (response.status === 200) {
      commit('setUserLeavesRequest', response.data);
      commit('setLoading', false);
    } else {
      commit('setLoading', false);
      this.$app.$router.push({name: 'UserLeaves'});
      this.$app.$snotify.error(this.$app.$t('messages.error.notFound',
        {entity: this.$app.$tc('models.leaveRequest.entity', 1)}));
    }
  },
  async updateLeaveRequest({commit}, leaveRequest) {
    commit('setLoading', true);
    const response = await this.$app.$http.put(`/leaves_requests/${leaveRequest.id}`, leaveRequest.data);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.leaveRequest.entity', 1)
      }));
    } else {
      commit('setLoading', false);
    }
    return response;
  },
  async destroyLeaveRequest({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.delete(`/leaves_requests/${data.id}`);
    if (response.status === 200) {
      data.table === 'user' ? commit('removeUserLeaveRequest', data.index) :
        commit('removePendingLeaveRequest', data.index);
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.deleted',
        {entity: this.$app.$tc('models.leaveRequest.entity', 1)}));
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.deleted',
        {entity: this.$app.$tc('models.leaveRequest.entity', 1)}));
    }
  },
  async getLeaveRequests({commit, rootState}, options) {
    commit('setLoading', true);
    options.filterBy.userId = options.filterBy.userId === 'me' ? rootState.session.currentUser.id : options.filterBy.userId;
    const params = new ListParams(options);
    const response = await this.$app.$http.get('/leaves_requests', {params: params});
    if (response.status === 200) {
      if (options.filterBy.userId) {
        commit('setUserLeavesRequests', response.data);
      } else {
        if (options.filterBy.status === leaveStatus.PENDING) {
          commit('setManagerPendingLeaves', response.data);
        } else {
          commit('setManagerHistoryLeaves', response.data);
        }
      }
      commit('setLoading', false);
    } else {
      commit('setLoading', false);
      this.$app.displayServerErrorsInToaster(response.errors);
    }
  },
  async manageLeaveRequest({rootState, commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.put(`/leaves_requests/${data.id}/manage`, data.form);
    if (response.status === 200) {
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {entity: this.$app.$tc('models.leaveRequest.entity', 1)}
      ));
      const updatedLeave = {
        index: data.index,
        leave: new LeaveRequest(response.data)
      };
      let managers = updatedLeave.leave.dates.map((date) => date.managers).flat(1);
      if (rootState.session.isAdmin) {
        managers = [...managers, rootState.session.currentUser.id];
      }
      if (data.index != null) {
        if (!managers.find((manager) => manager.id === rootState.session.currentUser.id &&
          manager.response === leaveStatus.PENDING) && !data.form.canceled_dates) {
          commit('moveToHistoryLeaveRequests', updatedLeave);
        } else {
          if (response.data.attributes.status === leaveStatus.PENDING) {
            commit('updatePendingLeaveRequest', updatedLeave);
          } else {
            commit('updateHistoryLeaveRequest', updatedLeave);
          }
        }
      }
      commit('setLoading', false);
    } else {
      commit('setLoading', false);
      this.$app.displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async getUserLeavesRequests({commit, rootState}, options) {
    commit('setLoading', false);
    const uuid = options.uuid === 'me' ? rootState.session.currentUser.id : options.uuid;
    const params = {...new ListParams(options), user_id: uuid};
    const response = await this.$app.$http.get('/leaves_requests', {params: params});
    if (response.status === 200) {
      commit('setUserLeavesRequests', response.data);
      commit('setLoading', false);
    } else {
      commit('setLoading', false);
      this.$app.displayServerErrorsInToaster(response.errors);
    }
  },
  async checkJiraTask({commit}, options) {
    commit('setLoading', true);
    const response = await this.$app.$http.get(`/leaves/check_jira_task?jira_task=${options.task}&id=${options.id}`);
    commit('setLoading', false);
    return response.status === 208;
  }
};

const mutations = {
  setLeaves: (state, data) => {
    state.leaves = new List({items: data.items.map((leave) => new Leave(leave)), count: data.count});
  },
  setUserLeave: (state, {data, index}) => state.userLeaves.items.splice(index, 1, new UserLeave(data)),
  setUserLeaves: (state, data) => {
    state.userLeaves = new List({
      items: data.items.map((leave) => new UserLeave(leave)),
      count: data.count
    });
  },
  setUserLeavesRequests: (state, data) => {
    state.userLeavesRequests = new List({
      items: data.items.map((leaveRequest) => new LeaveRequest(leaveRequest)),
      count: data.count
    });
  },
  setUserLeavesRequest: (state, data) => state.userLeavesRequest = new LeaveRequest(data),
  setManagerPendingLeaves: (state, data) => {
    state.managerPendingLeaves = new List({
      items: data.items.map((leaveRequest) => new LeaveRequest(leaveRequest)),
      count: data.count
    });
  },
  setManagerHistoryLeaves: (state, data) => {
    state.managerHistoryLeaves = new List({
      items: data.items.map((leaveRequest) => new LeaveRequest(leaveRequest)),
      count: data.count
    });
  },
  removeUserLeaveRequest(state, index) {
    state.userLeavesRequests.items.splice(index, 1);
  },
  removePendingLeaveRequest(state, index) {
    state.managerPendingLeaves.items.splice(index, 1);
  },
  updatePendingLeaveRequest(state, {index, leave}) {
    state.managerPendingLeaves.items[index].dates = leave.dates;
    state.managerPendingLeaves.items[index].status = leave.status;
  },
  updateHistoryLeaveRequest(state, {index, leave}) {
    state.managerHistoryLeaves.items[index].dates = leave.dates;
    state.managerHistoryLeaves.items[index].status = leave.status;
    state.managerHistoryLeaves.items[index].note = leave.note;
  },
  moveToHistoryLeaveRequests(state, {index, leave}) {
    state.managerHistoryLeaves.items.push(leave);
    state.managerPendingLeaves.items.splice(index, 1);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
