var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h4", [_vm._v(_vm._s(_vm.timezone))]),
      _c("h2", { staticClass: "pt-2" }, [
        _vm._v(" " + _vm._s(_vm.date.format("hh:mm"))),
        _c("span", { staticClass: "pl-1 clock-ampm" }, [
          _vm._v(_vm._s(_vm.date.format("A")))
        ])
      ]),
      _vm.event
        ? _c(
            "v-tooltip",
            {
              attrs: { left: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-icon",
                          _vm._g(
                            {
                              staticClass: "mr-1",
                              attrs: { "x-small": "", color: "primary" }
                            },
                            on
                          ),
                          [_vm._v("mdi-calendar-star")]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                745911872
              )
            },
            [
              _c("span", { staticStyle: { "white-space": "pre-wrap" } }, [
                _vm._v(_vm._s(_vm.event))
              ])
            ]
          )
        : _vm._e(),
      _c("span", { staticClass: "caption font-weight-medium" }, [
        _vm._v(_vm._s(_vm.date.format("ddd, DD MMMM")))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }