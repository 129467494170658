var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.onSubmit($event)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-row",
        [
          _vm._l(_vm.fields, function(field) {
            return _c(
              "v-col",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !field.hidden,
                    expression: "!field.hidden"
                  }
                ],
                key: field.key,
                class: field.classes
              },
              [
                _c(
                  "app-field",
                  _vm._b(
                    {
                      on: {
                        input: function($event) {
                          return _vm.bindValue($event, field)
                        },
                        "selection-changed": function($event) {
                          return _vm.selectionChanged(field)
                        },
                        "date-changed": function($event) {
                          return _vm.dateChanged(field)
                        },
                        "time-changed": function($event) {
                          return _vm.timeChanged(field)
                        },
                        "file-attached": function($event) {
                          return _vm.$emit("file-attached", $event)
                        },
                        "clear-server-errors": function($event) {
                          return _vm.clearServerErrors(field)
                        },
                        "text-changed": function($event) {
                          return _vm.textChanged(field)
                        }
                      }
                    },
                    "app-field",
                    field,
                    false
                  )
                )
              ],
              1
            )
          }),
          _c(
            "div",
            { class: _vm.footerClass },
            [
              _vm.showSubmitButton
                ? _c(
                    "v-btn",
                    {
                      class: _vm.submitButton.classes || "primary",
                      attrs: {
                        type: "submit",
                        disabled: !_vm.valid,
                        block: _vm.submitButton.block,
                        icon: _vm.submitButton.iconButton
                      }
                    },
                    [
                      _vm.submitButton.icon
                        ? _c(
                            "v-icon",
                            { attrs: { left: !_vm.submitButton.iconButton } },
                            [_vm._v(_vm._s(_vm.submitButton.icon))]
                          )
                        : _vm._e(),
                      !_vm.submitButton.iconButton
                        ? _c("span", [_vm._v(_vm._s(_vm.submitButton.label))])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.disableCancelButton
                ? _c(
                    "v-btn",
                    {
                      class: _vm.cancelButton.classes || "secondary ml-2",
                      attrs: {
                        block: _vm.cancelButton.block,
                        color: _vm.cancelButton.color,
                        icon: _vm.cancelButton.iconButton
                      },
                      on: { click: _vm.reset }
                    },
                    [
                      _vm.cancelButton.icon
                        ? _c(
                            "v-icon",
                            { attrs: { left: !_vm.cancelButton.iconButton } },
                            [_vm._v(_vm._s(_vm.cancelButton.icon))]
                          )
                        : _vm._e(),
                      !_vm.cancelButton.iconButton
                        ? _c("span", [_vm._v(_vm._s(_vm.cancelButton.label))])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }