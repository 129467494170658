var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: { bottom: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-avatar",
                _vm._g(
                  _vm._b(
                    { attrs: { size: _vm.actualSize, color: "primary" } },
                    "v-avatar",
                    _vm.tooltip ? attrs : "",
                    false
                  ),
                  _vm.tooltip ? on : ""
                ),
                [
                  _vm.url && !_vm.isError
                    ? _c("img", {
                        attrs: { src: _vm.url, alt: "" },
                        on: { error: _vm.onImgError }
                      })
                    : _c(
                        "span",
                        {
                          staticClass: "white--text headline",
                          style:
                            "font-size: " + _vm.actualSize / 2 + "px !important"
                        },
                        [_vm._v(" " + _vm._s(_vm.alternative) + " ")]
                      )
                ]
              )
            ]
          }
        }
      ])
    },
    [_c("span", [_vm._v(_vm._s(_vm.tooltip))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }