var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.modal.show,
            callback: function($$v) {
              _vm.$set(_vm.modal, "show", $$v)
            },
            expression: "modal.show"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline background" }, [
                _vm._v(_vm._s(_vm.modal.title))
              ]),
              _c(
                "v-card-text",
                [
                  _c("app-form", {
                    ref: "form",
                    attrs: {
                      fields: _vm.modal.fields,
                      submitButton: _vm.modal.submitButton,
                      resetForm: _vm.modal.resetForm,
                      disableCancelButton: ""
                    },
                    on: {
                      submitted: _vm.handleSubmit,
                      "selection-changed": _vm.selectionChanged,
                      "date-changed": _vm.dateChanged,
                      "time-changed": _vm.timeChanged,
                      "file-attached": _vm.handleAttach,
                      "text-changed": _vm.textChanged
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }