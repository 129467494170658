var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-5 col-sm-12 offset-md-7 offset-sm-0 pb-0" },
      [
        _c("v-select", {
          attrs: {
            items: _vm.items,
            label: _vm.label,
            outlined: "",
            dense: ""
          },
          on: { change: _vm.filterTable },
          model: {
            value: _vm.selection,
            callback: function($$v) {
              _vm.selection = $$v
            },
            expression: "selection"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }